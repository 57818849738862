@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.dropdown-menu {
  position: absolute;
  left: 25px;
  right: 25px;
  top: 100%;
  z-index: 100;
  padding-top: 10px;

  &.b-has-banner {
    .dropdown-menu_content {
      width: calc(100% - 373px);
      padding-right: 74px;
      @include desktopStyle {
        width: calc(100% - get-vw(373px));
        padding-right: get-vw(74px);
      }
    }
    .dropdown-menu_item {
      width: calc(33.333% - 80px);
      @include desktopStyle {
        width: calc(33.333% - get-vw(80px));
      }
    }
  }

  &.b-view-column {
    .dropdown-menu_content {
      gap: 50px;
      grid-gap: 50px;
      justify-content: flex-start;
      @include desktopStyle {
        gap: get-vw(50px);
        grid-gap: get-vw(50px);
      }
    }
    .dropdown-menu_list {
      display: block;
      width: 100%;
      max-width: 340px;
      @include desktopStyle {
        max-width: get-vw(340px);
      }
    }
    .dropdown-menu_item {
      margin-bottom: 32px;
      width: 100%;
      max-width: none;
      @include desktopStyle {
        margin-bottom: get-vw(32px);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .dropdown-menu_link {
      margin-bottom: 0;
      font-size: 2rem;
      line-height: 1.2;
    }
    .dropdown-submenu {
      width: 270px;
      @include desktopStyle {
        width: get-vw(270px);
      }
    }
  }

  &_wrapper {
    background: var(--white);
    box-shadow: 0px 4px 32px rgba(var(--black-rgba), 0.05);
    border-radius: 30px;
    padding: 50px;
    @include desktopStyle {
      padding: get-vw(50px);
    }
  }

  &_content {
    width: 100%;
  }

  &_list {
    gap: 67px 120px;
    grid-gap: 67px 120px;
    width: 100%;
    @include desktopStyle {
      gap: get-vw(67px) get-vw(120px);
      grid-gap: get-vw(67px) get-vw(120px);
    }
  }

  &_item {
    width: calc(25% - 90px);
    max-width: 270px;
    @include desktopStyle {    
      width: calc(25% - get-vw(90px));
      max-width: get-vw(270px);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_link {
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
    color: var(--gray-dark);
    text-decoration: none;
    gap: 12px;
    grid-gap: 12px;
    margin-bottom: 32px;
    cursor: pointer;
    @include desktopStyle {
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
      margin-bottom: get-vw(32px);
    }
    &.b-focus,
    &:hover {
      color: var(--blue);
      text-decoration: underline;
      img {
        filter: invert(66%) sepia(9%) saturate(6463%) hue-rotate(178deg) brightness(87%) contrast(91%);
      }
    }
    img {
      min-width: 20px;
      width: 20px;
      height: 20px;
      transition: filter 0.3s;
      object-fit: contain;
      @include desktopStyle {
        min-width: get-vw(20px);
        width: get-vw(20px);
        height: get-vw(20px);
      }
    }
  }
}

.dropdown-submenu {
  &_item {
    margin-bottom: 12px;
    @include desktopStyle {
      margin-bottom: get-vw(12px);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_link {
    font-family: $font-main-MD;
    font-size: 1.6rem;
    line-height: 1.6;
    color: var(--gray-dark);
    text-decoration: none;
    &:hover {
      color: var(--blue);
      .dropdown-submenu_text {
        text-decoration: underline;
      }
    }
  }

  &_label {
    background: rgba(var(--green-rgba), 0.2);
    font-family: $font-main-SB;
    font-size: 1.2rem;
    line-height: 1.25;
    padding: 3px 8px;
    border-radius: 66px;
    color: var(--green);
  }
}
