@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.mobile-menu {
  overflow: hidden;

  &_app {
    width: 100%;
    margin-bottom: 16px;
  }
  &_setting {
    height: 100%;
    :global(.visual-impairments-settings) {
      position: relative;
      box-shadow: none;
      background: transparent;
      height: calc(100% - 72px);
    }
    :global(.visual-impairments-settings_wrapper) {
      max-height: none;
      overflow: visible;
      padding: 0;
    }
    :global(.visual-impairments-settings_top) {
      display: none;
    }
    :global(.visual-impairments-settings_bottom) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
  &_setting-top {
    border-bottom: 1px solid #F0F0F0;
    margin-bottom: 24px;
  }
  &_setting-back {
    gap: 10px;
    grid-gap: 10px;
    padding: 10px 0;
    color: var(--gray-light);
    cursor: pointer;
  }

  &_top {
    border-bottom: 1px solid #F0F0F0;
    margin-bottom: 24px;
  }

  &_tabs {
    gap: 32px;
    grid-gap: 32px;
  }

  &_tabs-item {
    color: var(--gray-light);
    position: relative;

    &.b-active {
      &::after {
        width: 100%;
        opacity: 1;
        visibility: visible;
      }
    }

    &.b-active,
    &:hover {
      color: var(--blue);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: -1px;
      border: none;
      width: 0;
      height: 2px;
      background: var(--blue);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
    }
  }

  &_tabs-link {
    color: inherit;
    font-size: 1.4rem;
    display: block;
    text-decoration: none;
    padding: 10px 0;
  }

  &_main {
    margin-top: 24px;
  }

  &_main-item {
    border-bottom: 1px solid #F0F0F0;
    position: relative;
  }

  &_main-link {
    text-decoration: none;
    color: var(--gray-dark);
    padding: 14px 24px 14px 0;
    gap: 12px;
    grid-gap: 12px;
    font-size: 1.5rem;
    line-height: 1.3;
  }

  &_main-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
  }

  &_main-icon {
    width: 16px;
    height: 16px;
    img {
      display: block;
      max-height: 100%;
    }
  }

  &_button {
    margin-top: 32px;
    gap: 8px;
    grid-gap: 8px;
  }
  
  &_bottom-menu {
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin-top: 32px;
  }
  &_bottom-menu-item {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_bottom-menu-link {
    color: var(--gray);
    text-decoration: none;
  }

  &_action {
    margin-top: 32px;
    gap: 25px;
    grid-gap: 25px;
  }

  &_action-btn {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    gap: 12px;
    grid-gap: 12px;
    color: var(--gray-light);
    font-family: inherit;
    position: relative;
    display: flex;
    &:hover {
      color: var(--blue);
    }
    svg {
      display: block;
    }
  }
}
