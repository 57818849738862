@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.header {
  background: var(--blue-light);
  padding: 20px 0;
  font-family: $font-main-SB;
  font-size: 1.6rem;
  @include desktopStyle {
    padding-top: get-vw(20px);
    padding-bottom: get-vw(20px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 8px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999999;
    background: transparent;
  }

  :global {
    .visual-impairments_btn {
      width: auto;
    }
    .header-link {
      line-height: 1.3;
      color: var(--gray);
      text-decoration: none;
      &:hover {
        color: var(--blue);
      }

      &.b-with-icon {
        gap: 10px;
        grid-gap: 10px;
        @include desktopStyle {
          grid-gap: get-vw(10px);
          gap: get-vw(10px);
        }
      }
    }

    .header-link_icon {
      color: var(--green);
      @include desktopStyle {
        width: get-vw(14px);
        height: get-vw(14px);
      }
    }

    .btn {
      font-size: 1.6rem;
      padding: 10px 22px;
      line-height: 1.25;
      @include desktopStyle {
        padding: get-vw(10px) get-vw(22px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.4rem;
        line-height: 1.3;
        border-radius: 70px;
        padding: 8px 14px;
      }
    }
    .btn.bg-white {
      border-width: 1px;
      padding-top: 11px;
      padding-bottom: 11px;
      border-color: var(--gray-second);
      @include desktopStyle {
        padding-top: get-vw(11px);
        padding-bottom: get-vw(11px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding: 9px 13px;
      }
      svg {
        color: var(--bg-pink-dark);
        @include desktopStyle {
          width: get-vw(11px);
          height: get-vw(15px);
        }
      }
      &:hover {
        background: var(--bg-pink-dark);
        border-color: var(--bg-pink-dark);
        color: var(--white);
        svg {
          color: var(--white);
        }
      }
    }

    .header_search-btn {
      background: transparent;
      padding: 0;
      border: none;
      cursor: pointer;
      outline: none;
      width: 24px;
      height: 24px;
      color: var(--gray-light);
      transition: color 0.3s;
      margin-left: 28px;
      @include desktopStyle {
        margin-left: get-vw(28px);
        width: get-vw(24px);
        height: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        width: 20px;
        height: 20px;
      }
      &:hover {
        color: var(--blue);
      }
    }
  }
}
