@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.header-mobile {
  display: block;
  @media only screen and (min-width: $bp-tablet + 1) {
    display: none;
  }

  &.b-open {
    .header-mobile_wrapper {
      box-shadow: none;
    }
  }

  &_app-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    overflow: auto;
    background: rgba(58, 66, 82, 0.50);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    &-inner {
      margin: auto;
      padding: 16px;
    }
  }

  &_wrapper {
    background: var(--white);
    box-shadow: 0 4px 32px 0 rgba(var(--black-rgba), 0.05);
    position: relative;
    border-radius: 90px;
    z-index: 2;
    transition: all 0.3s;
    &:global(.wr) {
      padding: 16px 97px 16px 22px;
    }
    :global(.logo) {
      img {
        width: auto;
        height: auto;
      }
    }
  }

  &_btn {
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -12px;
    &.b-open {
      &::after,
      &::before {
        background: var(--gray);
        border-radius: 4px;
      }
      &::after {
        transform: rotate(-45deg);
        top: 11px;
      }
      &::before {
        transform: rotate(45deg);
        bottom: 11px;
      }
    }
    &::after,
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background: var(--blue);
      left: 2px;
      transition: all 0.3s;
    }
    &:after {
      top: 8px;
    }
    &::before {
      bottom: 8px;
    }
  }

  &_content {
    position: absolute;
    background: var(--white);
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 92px;
    height: 100vh;
    height: 100dvh;
    padding: 92px 10px 25px;
    z-index: 1;
  }

  &_inner {
    height: 100%;
    overflow: auto;
    padding: 0 14px;
    position: relative;
  }
}
