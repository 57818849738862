@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.header-search {
  width: 542px;
  border-radius: 90px;
  background: var(--white);
  position: relative;
  box-shadow: 0 4px 32px 0 rgba(var(--black-rgba), 0.05);
  overflow: hidden;
  @include desktopStyle {
    width: get-vw(542px);
    border-radius: get-vw(90px);
    box-shadow: 0 get-vw(4px) get-vw(32px) 0 rgba(var(--black-rgba), 0.05);
  }
  @media only screen and (max-width: $bp-tablet) {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 30px;
    overflow: visible;
  }

  :global(.form_error-text) {
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 60px;
    padding-right: 30px;
    bottom: 8px;
    white-space: nowrap;
    font-size: 1.1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 0;
    @include desktopStyle {
      padding-left: get-vw(60px);
      padding-right: get-vw(30px);
      bottom: get-vw(8px);
    }
    @media only screen and (max-width: $bp-tablet) {
      bottom: unset;
      top: 100%;
      padding: 6px 0 0;
    }
  }

  &_btn {
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;
    width: 20px;
    height: 20px;
    transition: color 0.3s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include desktopStyle {
      width: get-vw(20px);
      height: get-vw(20px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 16px;
      height: 16px;
    }
    &.b-search {
      color: var(--gray-dark);
      left: 28px;
      @include desktopStyle {
        left: get-vw(28px);
      }
      @media only screen and (max-width: $bp-tablet) {
        display: none;
      }
    }
    &.b-close {
      color: var(--gray-light);
      right: 28px;
      @include desktopStyle {
        right: get-vw(28px);
      }
      @media only screen and (max-width: $bp-tablet) {
        color: var(--gray);
        right: 0;
      }
    }
    &:hover {
      color: var(--blue);
    }
  }

  &_input {
    display: block;
    width: 100%;
    background: transparent;
    outline: none;
    cursor: pointer;
    padding: 27px 88px 27px 60px;
    border: none;
    font-family: $font-main-SB;
    font-size: 1.6rem;
    color: var(--gray-dark);
    @include desktopStyle {
      padding: get-vw(27px) get-vw(88px) get-vw(27px) get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 10px 26px 10px 0;
      font-size: 1.5rem;
      border-bottom: 1px solid #F0F0F0;
      line-height: 1.3;
      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }
    }
    &::placeholder {
      transition: opacity 0.3s;
      color: var(--gray-light);
    }
  }
}