@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .footer-bottom {
    border-top-color: #E8E8E8;
  }
}

.footer {
  font-family: $font-main-MD;
  line-height: 1.6;
  color: var(--gray-dark);
  position: relative;

  &-top {
    padding-top: 100px;
    padding-bottom: 80px;
    @include desktopStyle {
      padding-top: get-vw(100px);
      padding-bottom: get-vw(80px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 60px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 40px;
    }
  }

  &-column {
    width: 23%;
    max-width: 285px;
    @include desktopStyle {
      max-width: get-vw(285px);
      &:last-child {
        max-width: get-vw(292px);
      }
    }
    &:last-child {
      max-width: 292px;
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
      &:first-child {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column-reverse;
      }
      &:last-child {
        max-width: none;
      }
    }
  }

  &-action {
    margin-top: 67px;
    gap: 90px;
    grid-gap: 90px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @include desktopStyle {
      margin-top: get-vw(67px);
      gap: get-vw(90px);
      grid-gap: get-vw(90px);
    }
    &:first-child {
      margin-top: 0;
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 0;
      padding-top: 48px;
      border-top: 1px solid rgba(var(--gray-second-rgba), 0.4);
      gap: 48px;
      grid-gap: 48px;
      flex-direction: column-reverse;
    }

    &_app {
      width: 100%;
      &-title {
        font-family: $font-main-MD;
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 24px;
        @include desktopStyle {
          margin-bottom: get-vw(24px);
        }
      }
      &-list {
        a {
          &:not(:last-child) {
            margin-bottom: 8px;
            @include desktopStyle {
              margin-bottom: get-vw(8px);
            }
          }
        }
      }
    }
  }

  &-bottom {
    padding: 40px 0;
    border-top: 1px solid var(--gray-second);
    @include desktopStyle {
      padding: get-vw(40px) 0;
    }
    :global(.wr) {
      position: relative;
      gap: 40px;
      grid-gap: 40px;
      padding-right: 160px;
      @media only screen and (max-width: $bp-tablet) {
        gap: 32px;
        grid-gap: 32px;
      }
      @media only screen and (max-width: $bp-phone) {
        padding-right: 16px;
        flex-wrap: wrap;
      }
    }
  }

  &-bottom_logos {
    gap: 25px;
    grid-gap: 25px;
    @include desktopStyle {
      gap: get-vw(25px);
      grid-gap: get-vw(25px);
    }
    @media only screen and (max-width: $bp-phone) {
      position: relative;
      padding-right: 110px;
      width: 100%;
    }

    :global(.logo) {
      @media only screen and (max-width: $bp-phone) {
        max-width: 100px;
      }

      img {
        width: auto;
        height: auto;
      }
    }
  }

  &-bottom_copyright {
    font-size: 1.4rem;
    line-height: 1.6;
    color: rgba(var(--gray-dark-rgba), 0.5);
    max-width: 405px;
    @include desktopStyle {
      max-width: get-vw(405px);
    }
    @media only screen and (max-width: $bp-phone) {
      line-height: 1.3;
      font-size: 1.1rem;
      max-width: none;
    }
  }

  &-development {
    width: 120px;
    text-decoration: none;
    font-family: $font-main;
    font-size: 10px;
    line-height: 1.6;
    color: rgba(var(--gray-dark-rgba), 0.6);
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    gap: 10px;
    grid-gap: 10px;
    @include desktopStyle {
      width: get-vw(120px);
      font-size: get-vw(10px);
      right: get-vw(16px);
      gap: get-vw(10px);
      grid-gap: get-vw(10px);
      img {
        width: get-vw(42px);
      }
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 7px;
      right: 0;
      width: 90px;
      img {
        width: 31px;
      }
    }
  }
}

.footer-menu {
  font-size: 1.6rem;
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.4rem;
  }

  &_item {
    margin-bottom: 12px;
    @include desktopStyle {
      margin-bottom: get-vw(12px);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_link {
    color: rgba(var(--gray-dark-rgba), 0.8);
    text-decoration: none;
    &:hover {
      color: var(--blue);
      text-decoration: underline;
    }
  }
}

.footer-social {
  margin-top: 62px;
  width: 100%;
  @include desktopStyle {
    margin-top: get-vw(62px);
  }
  &:first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 0;
    margin-bottom: 50px;
  }

  &_title {
    font-size: 1.4rem;
    margin-bottom: 22px;
    @include desktopStyle {
      margin-bottom: get-vw(22px);
    }
    @media only screen and (max-width: $bp-tablet) {
      line-height: 1.4;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  &_list {
    gap: 12px;
    grid-gap: 12px;
    @include desktopStyle {
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      justify-content: center;
    }
  }

  &_link {
    width: 37px;
    height: 37px;
    text-decoration: none;
    @include desktopStyle {
      width: get-vw(37px);
      height: get-vw(37px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 48px;
      height: 48px;
      img {
        transform: scale(1.2);
      }
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      display: block;
      object-fit: contain;
    }
  }
}
