@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.subscribe-form {
  gap: 8px;
  grid-gap: 8px;
  width: 100%;

  :global(.form_checkbox) {
    font-size: 1.4rem;
    display: block;
    margin-top: 8px;
    font-family: inherit;
  }

  &_label {
    font-size: 1.4rem;
    line-height: 1.6;
    color: var(--gray);
    margin-bottom: 8px;
  }

  &_control {
    gap: 8px;
    grid-gap: 8px;
  }

  &_input {
    &:global(.input) {
      width: calc(100% - 56px);
      font-size: 1.6rem;
      line-height: 1.6;
      padding-top: 12px;
      padding-bottom: 12px;
      border: 1px solid var(--gray-second);
      @include desktopStyle {
        width: calc(100% - get-vw(56px));
        padding-top: get-vw(12px);
        padding-bottom: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - 44px);
      }
      &::placeholder {
        color: rgba(var(--gray-dark-rgba), 0.5);
        opacity: 1;
        transition: opacity 0.3s;
      }
      &:focus {
        &::placeholder {
          opacity: 0;
        } 
      }
    }
  }

  :global(.btn.b-only-icon) {
    width: 48px;
    height: 48px;
    @include desktopStyle {
      width: get-vw(48px);
      height: get-vw(48px);
      svg {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 36px;
      height: 36px;
    }
  }

  &_success {
    font-family: $font-main-MD;
    font-size: 1.4rem;
    line-height: 1.6;
    color: var(--gray);
    margin-top: 8px;
    gap: 10px;
    grid-gap: 10px;
    @include desktopStyle {
      gap: get-vw(10px);
      grid-gap: get-vw(10px);
    }
    svg {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      @include desktopStyle {
        width: get-vw(18px);
        height: get-vw(18px);
      }
    }
  }
}
