@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.lang-dropdown {
  font-family: $font-main-SB;
  font-size: 1.6rem;
  line-height: 1.3;
  width: 55px;
  position: relative;
  cursor: pointer;
  height: 23px;
  padding-right: 16px;
  padding-left: 10px;
  overflow: hidden;
  z-index: 101;
  @include desktopStyle {
    width: get-vw(55px);
    height: get-vw(23px);
    padding-right: get-vw(16px);
    padding-left: get-vw(10px);
  }
  @media only screen and (max-width: $bp-tablet) {
    font-size: 1.3rem;
    padding-left: 0;
    width: auto;
    height: auto;
  }
  &:hover {
    overflow: visible;
    .lang-dropdown_content {
      max-height: 125px;
      background-color: var(--white);
      box-shadow: 0 5px 10px rgba(var(--black-rgba), 0.05);
      @media only screen and (max-width: $bp-tablet) {
        box-shadow: none;
      }
    }
    &::after {
      transform: rotate(180deg);
    }
  }
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    transition: transform 0.3s;
    right: 5px;
    border-top: 5px solid var(--gray);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    margin-top: -3px;
    @include desktopStyle {
      right: get-vw(5px);
      border-top-width: get-vw(5px);
      border-left-width: get-vw(4px);
      border-right-width: get-vw(4px);
      margin-top: get-vw(-3px);
    }
    @media only screen and (max-width: $bp-tablet) {
      border-top-color: var(--gray-light);
      top: 6px;
      margin-top: 0;
    }
  }

  &_content {
    transition: all 0.3s;
    position: absolute;
    top: -5px;
    left: 0;
    right: -5px;
    max-height: 26px;
    overflow: hidden;
    border-radius: 5px;
    @include desktopStyle {
      top: get-vw(-5px);
      right: get-vw(-5px);
      max-height: get-vw(26px);
    }
    @media only screen and (max-width: $bp-tablet) {
      position: relative;
      top: 0;
      right: 0;
      max-height: 19px;
    }
  }

  &_item {
    width: 100%;
    color: var(--gray);
    @media only screen and (max-width: $bp-tablet) {
      color: var(--gray-light);
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    &:hover {
      color: var(--blue);
    }
  }

  &_link {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    padding: 5px 10px;
    @include desktopStyle {
      padding: get-vw(5px) get-vw(10px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 0;
    }
  }
}