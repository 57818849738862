@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"] {
  .banner {
    background: transparent !important;
    border: 1px solid var(--gray-dark);
    &_label {
      background: var(--bg-blue-light-2) !important;
    }
  }
}

[data-color-scheme="blackWhite"],
[data-color-scheme="blue"] {
  .banner {
    background: transparent !important;
    border: 1px solid var(--black);
    &_label {
      background: var(--bg-blue-light-2) !important;
    }
  }
}

.banner {
  height: 500px;
  border-radius: 12px;
  font-size: 2rem;
  line-height: 1.6;
  text-align: center;
  color: var(--gray-dark);
  font-family: $font-main-MD;
  width: 373px;
  padding: 32px 30px 16px;
  position: relative;
  background: var(--bg-blue-2);
  @include desktopStyle {
    width: get-vw(373px);
    padding: get-vw(32px) get-vw(30px) get-vw(16px);
    height: get-vw(500px);
  }

  $colors:
    'green' var(--bg-green-4) var(--bg-green-2),
    'green_light' var(--bg-green-light-2) var(--bg-green-light),
    'green_dark' var(--bg-green) var(--bg-green-light-2),
    //'blue' var(--bg-blue-2), // default
    'blue_light' var(--bg-blue-light) var(--bg-label-blue-2),
    'blue_sky' var(--bg-blue-sky) var(--bg-blue-light-4),
    'violet_light' var(--bg-violet-light) var(--bg-violet-light-2),
    'brown' var(--bg-brown) var(--bg-brown-light),
    'brown_light' var(--bg-brown-light) var(--bg-brown-light-3),
    'pink' var(--bg-pink) var(--pink-light),
    'pink_light' var(--bg-pink-light) var(--bg-pink-light-2),
    'yellow' var(--bg-yellow-2) var(--bg-label-yellow),
    'yellow_secondary' var(--bg-yellow) var(--bg-yellow-light-3),
    'turquoise' var(--bg-turquoise) var(--bg-turquoise-light);
  @each $name, $bgBlock, $bgLabel in $colors {
    &.bg-#{$name} {
      background: $bgBlock;
      .banner_label {
        background: $bgLabel;
      }
    }
  }

  &.b-has-text {
    .banner_title {
      font-size: 3.2rem;
      line-height: 1.3;
    }
  }

  &_over-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: block;
    text-decoration: none;
    outline: none;
  }
  
  &_label,
  &_title,
  &_text,
  &_btn {
    position: relative;
    z-index: 2;
  }

  &_label {
    font-size: 1.6rem;
    line-height: 1.6;
    padding: 4px 16px;
    margin-bottom: 36px;
    border-radius: 60px;
    background: var(--bg-label-blue-2);
  }

  &_title {
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
  }

  &_text {
    color: var(--gray);
  }

  &_btn {
    margin-top: auto;
    z-index: 4;
    &:global(.btn.bg-white) {
      &:hover {
        background: var(--blue-light-2) !important;
        border-color: var(--blue-light-2) !important;
      }
    }
  }

  &_image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
      display: block;
      object-fit: contain;
      width: 100%;
    }
  }
}