@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.visual-impairments {
  position: relative;

  &_btn {
    width: 100%;
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    gap: 12px;
    grid-gap: 12px;
    color: var(--gray);
    font-family: inherit;
    position: relative;
    display: flex;
    @include desktopStyle {
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
    }
    &:hover {
      color: var(--blue);
      .visual-impairments_text {
        color: var(--blue);
      }
    }
    svg {
      display: block;
      @include desktopStyle {
        width: get-vw(20px);
        height: get-vw(20px);
      }
    }

    &.is-tooltip {
      &:hover {
        .visual-impairments_text {
          opacity: 1;
          visibility: visible;
        }
      }
      .visual-impairments_text {
        pointer-events: none;
        position: absolute;
        right: 100%;
        background: var(--white);
        box-shadow:
          0 4px 6px -2px rgba(var(--gray-dark-2-rgba), 0.05),
          0 4px 16px -4px rgba(var(--gray-dark-2-rgba), 0.1);
        top: 50%;
        margin-right: 14px;
        transform: translateY(-50%);
        white-space: nowrap;
        max-width: none;
        padding: 8px 12px;
        z-index: 6;
        border-radius: 8px;
        color: var(--gray);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        @include desktopStyle {
          box-shadow:
            0 get-vw(4px) get-vw(6px) get-vw(-2px) rgba(var(--gray-dark-2-rgba), 0.05),
            0 get-vw(4px) get-vw(16px) get-vw(-4px) rgba(var(--gray-dark-2-rgba), 0.1);
          margin-right: get-vw(14px);
          padding: get-vw(8px) get-vw(12px);
          border-radius: get-vw(8px);
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 100%;
          top: 50%;
          margin-top: -5px;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 8px solid var(--white);
          filter: none;
          @include desktopStyle {
            margin-top: get-vw(-5px);
            border-top-width: get-vw(5px);
            border-bottom-width: get-vw(5px);
            border-left-width: get-vw(8px);
          }
        }
      }
    }
  }

  &_text {
    color: var(--gray-dark);
    transition: all 0.3s;
    font-size: 1.5rem;
    line-height: 1.6;
    gap: 12px;
    grid-gap: 12px;
    max-width: calc(100% - 32px);
    @include desktopStyle {
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
      max-width: calc(100% - get-vw(32px));
    }
  }
}
