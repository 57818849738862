@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.header-menu {
  font-family: $font-main-MD;
  width: 100%;
  background: var(--white);
  border-radius: 90px;
  padding: 0 40px;
  box-shadow: 0 4px 32px 0 rgba(var(--black-rgba), 0.05);
  flex: 1;
  transition: all 0.3s;
  @include desktopStyle {
    padding-left: get-vw(40px);
    padding-right: get-vw(40px);
    box-shadow: 0 get-vw(4px) get-vw(32px) 0 rgba(var(--black-rgba), 0.05);
  }

  &_container {
    width: 100%;
    margin-top: 20px;
    :global(.wr) {
      max-width: 1678px;
      @include desktopStyle {
        max-width: get-vw(1678px);
      }
    }
  }

  &_wrapper {
    width: 100%;
    &.is-sticky {
      animation: fixedMenu .6s forwards;
      top: 0;
      position: relative;
      z-index: 20;
    }
  }

  &_content {
    gap: 24px;
    grid-gap: 24px;
    position: relative;
    width: 100%;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
    }
  }

  &_tabs {
    gap: 20px 40px;
    grid-gap: 20px 40px;
    margin-right: 102px;
    @include desktopStyle {
      gap: get-vw(20px) get-vw(40px);
      grid-gap: get-vw(20px) get-vw(40px);
      margin-right: get-vw(102px);
    }
  }

  &_tabs-item {
    color: var(--gray-light);
    position: relative;
    &.b-focus,
    &:hover {
      color: var(--blue);
    }
    &.b-focus {
      &::after {
        width: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      border: none;
      width: 0;
      height: 2px;
      background: var(--blue);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
    }
  }

  &_list {
    gap: 32px;
    grid-gap: 32px;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
    }
  }

  &_item {
    color: var(--gray-dark);
    position: relative;
    &.b-has-children {
      .header-menu_link {
        padding-right: 16px;
        @include desktopStyle {
          padding-right: get-vw(16px);
        }
      }
      &::after {
        content: "";
      }
    }
    &.b-focus,
    &:hover {
      color: var(--blue);
      &::after {
        transform: rotate(180deg);
        border-top-color: var(--blue);
      }
    }
    &::after {
      position: absolute;
      display: block;
      top: 50%;
      margin-top: -2px;
      transition: all 0.3s;
      right: 0;
      border-top: 5px solid var(--gray-dark);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      @include desktopStyle {
        margin-top: get-vw(-2px);
        border-top-width: get-vw(5px);
        border-left-width: get-vw(4px);
        border-right-width: get-vw(4px);
      }
    }
  }

  &_link {
    color: inherit;
    display: block;
    text-decoration: none;
    padding: 24px 0;
    cursor: pointer;
    @include desktopStyle {
      padding-top: get-vw(24px);
      padding-bottom: get-vw(24px);
    }
  }

  &_action {
    gap: 12px;
    grid-gap: 12px;
    margin-left: auto;
    @include desktopStyle {
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
    }
  }

  &_app {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    &:not(:last-child) {
      margin-right: 36px;
      @include desktopStyle {
        margin-right: get-vw(36px);
      }
    }
    &-popup {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 12px;
      z-index: 20;
      @include desktopStyle {
        margin-top: get-vw(12px);
      }
    }
  }
}

@keyframes fixedMenu  {
  0%   { transform: translateY(-100%); position: fixed;}
  50%   { transform: translateY(-100%); position: fixed;}
  100%   { transform: translateY(0); position: fixed;}
}
