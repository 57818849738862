@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.visual-impairments-settings {
  position: absolute;
  border-radius: 12px;
  z-index: 101;
  top: 100%;
  right: 0;
  margin-top: 20px;
  background: var(--white);
  box-shadow: 0px 4px 50px 0px rgba(var(--black-rgba), 0.25);
  @include desktopStyle {
    border-radius: get-vw(12px);
    margin-top: get-vw(20px);
    box-shadow: 0px get-vw(4px) get-vw(50px) 0px rgba(var(--black-rgba), 0.25);
  }
  @media only screen and (max-width: $bp-tablet) {
    position: fixed;
    top: unset;
    margin-top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }

  &_wrapper {
    position: relative;
    padding: 32px;
    width: 100%;
    @include desktopStyle {
      padding: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 24px 20px 0;
      max-height: calc(100vh - 144px);
      overflow: auto;
    }
  }

  &_reset-btn {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    gap: 8px;
    grid-gap: 8px;
    text-decoration: underline;
    font-size: 18px;
    font-family: $font-main-SB;
    line-height: 1.3;
    color: var(--black);
    @include desktopStyle {
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
      font-size: get-vw(18px);
      svg {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
    @media only screen and (max-width: $bp-tablet) {
      display: none;
    }
  }

  &_btn-close {
    right: 24px;
    top: 24px;
    position: absolute;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;
    width: 24px;
    height: 24px;
    @include desktopStyle {
      right: get-vw(24px);
      top: get-vw(24px);
      width: get-vw(24px);
      height: get-vw(24px);
    }
    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 91%;
      background: #898f9a;
      border-radius: 4px;
      top: 3%;
      left: 50%;
      margin-left: -1px;
      @include desktopStyle {
        width: get-vw(2px);
        margin-left: get-vw(-1px);
      }
    }
    &::after {
      transform: rotate(-45deg);
    }
    &::before {
      transform: rotate(45deg);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: none;
    }
  }

  &_title {
    font-size: 24px;
    @include desktopStyle {
      font-size: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 22px;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 20px;
    }
  }

  &_top {
    padding-right: 40px;
    gap: 24px;
    grid-gap: 24px;
    @include desktopStyle {
      padding-right: get-vw(40px);
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-right: 0;
    }
  }

  &_top,
  &_block {
    margin-bottom: 32px;
    @include desktopStyle {
      margin-bottom: get-vw(32px);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_block {
    gap: 16px;
    grid-gap: 16px;
    @include desktopStyle {
      gap: get-vw(16px);
      grid-gap: get-vw(16px);
    }
    @media only screen and (max-width: $bp-phone) {
      flex-wrap: wrap;
      gap: 12px;
      grid-gap: 12px;
    }
    &.b-font-size {
      .visual-impairments-settings_option {
        padding: 4px 28px;
        font-size: 16px;
        @include desktopStyle {
          padding: get-vw(4px) get-vw(28px);
          font-size: get-vw(16px);
        }
        @media only screen and (max-width: $bp-tablet) {
          font-size: 14px;
        }
        &:nth-child(2) {
          font-size: 20px;
          @include desktopStyle {
            font-size: get-vw(20px);
          }
          @media only screen and (max-width: $bp-tablet) {
            font-size: 18px;
          }
        }
        &:nth-child(3) {
          font-size: 24px;
          @include desktopStyle {
            font-size: get-vw(24px);
          }
          @media only screen and (max-width: $bp-tablet) {
            font-size: 22px;
          }
        }
      }
    }
    &.b-color-scheme {
      .visual-impairments-settings_option {
        &:nth-child(3) {
          .visual-impairments-settings_option-icon {
            background: #151413;
            color: #fff;
          }
        }
        &:nth-child(4) {
          .visual-impairments-settings_option-icon {
            background: #aacffa;
            border-color: #aacffa;
            color: #000;
          }
        }
      }
    }
  }

  &_name {
    line-height: 1.5;
    width: 150px;
    font-size: 18px;
    @include desktopStyle {
      width: get-vw(150px);
      font-size: get-vw(18px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 16px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
    }
  }

  &_options {
    gap: 8px;
    grid-gap: 8px;
    @include desktopStyle {
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
    }
    @media only screen and (max-width: $bp-tablet) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
    }
  }

  &_option {
    background: transparent;
    outline: none;
    white-space: nowrap;
    font-size: 18px;
    font-family: $font-main-SB;
    line-height: 1.3;
    padding: 6px 22px;
    border-radius: 90px;
    border: 2px solid var(--gray-2);
    transition: all 0.3s;
    cursor: pointer;
    gap: 8px;
    grid-gap: 8px;
    color: var(--black);
    @include desktopStyle {
      border-width: get-vw(2px);
      font-size: get-vw(18px);
      padding: get-vw(6px) get-vw(22px);
      border-radius: get-vw(90px);
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 15px;
    }
    &.b-active {
      border-color: var(--gray-dark-3);
    }
    &.has-icon {
      padding-right: 14px;
      @include desktopStyle {
        padding-right: get-vw(14px);
      }
    }
  }

  &_option-icon {
    display: block;
    border-radius: 100%;
    border: 1px solid var(--gray-dark);
    width: 23px;
    height: 23px;
    font-size: 14px;
    line-height: 21px;
    color: var(--gray-dark);
    font-family: $font-main-B;
    @include desktopStyle {
      width: get-vw(23px);
      height: get-vw(23px);
      font-size: get-vw(14px);
      line-height: get-vw(21px);
    }
  }

  &_bottom {
    gap: 8px;
    grid-gap: 8px;
    margin-top: auto;
    padding: 18px 20px;
    width: 100%;
    display: none;
    @media only screen and (max-width: $bp-tablet) {
      display: flex;
    }
    :global(.btn) {
      border-width: 1px;
      font-size: 14px;
      justify-content: center;
      width: calc(50% - 4px);
      padding: 9px 10px;
    }
    :global(.btn.bg-bordered) {
      color: var(--gray-dark);
      border-color: var(--gray-second);
      &:hover {
        color: var(--white);
      }
    }
  }
}
