@mixin fontFace($FontName, $FontPathName) {
  @font-face {
    font-family: '#{$FontName}';
    src: url('~~/app/assets/fonts/#{$FontPathName}.ttf') format('truetype'),
      url('~~/app/assets/fonts/#{$FontPathName}.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@function get-vw($target) {
  $vw-context: (1920 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

@mixin desktopStyle {
  @media only screen and (max-width: $bp-desktop-lg - 1) and (min-width: $bp-tablet + 1) {
    @content;
  }
}

@function percent-width-flex-items($items, $gap) {
  @return calc(calc(100% / $items) - (calc(($items - 1) / $items) * $gap));
}
