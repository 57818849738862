@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.header {
  &-desktop {
    display: none;
    @media only screen and (min-width: $bp-tablet + 1) {
      display: block;
    }
  }

  &_dropdown {
    position: relative;
    font-size: 1.6rem;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      margin-top: -2px;
      transition: transform 0.3s;
      right: 0;
      border-top: 5px solid var(--gray);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      @include desktopStyle {
        margin-top: get-vw(-2px);
        border-top-width: get-vw(5px);
        border-left-width: get-vw(4px);
        border-right-width: get-vw(4px);
      }
    }
    &:hover {
      &::after {
        transform: rotate(180deg);
      }
      .header_dropdown-list {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_dropdown-name {
    color: var(--gray);
    cursor: pointer;
    padding-right: 17px;
    @include desktopStyle {
      padding-right: get-vw(17px);
    }
  }

  &_dropdown-list {
    white-space: nowrap;
    padding-top: 16px;
    position: absolute;
    right: -10px;
    top: 100%;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    @include desktopStyle {
      padding-top: get-vw(16px);
    }
  }

  &_dropdown-inner {
    white-space: nowrap;
    padding: 4px 0;
    background: var(--white);
    border-radius: 12px;
    border: 1px solid var(--gray-light-3);
    box-shadow:
      0px 4px 6px -2px rgba(var(--gray-dark-2-rgba), 0.03),
      0px 12px 16px -4px rgba(var(--gray-dark-2-rgba), 0.08);
  }

  &_dropdown-link {
    display: block;
    padding: 12px 24px;
    text-decoration: none;
    color: var(--gray-dark-2);
    @include desktopStyle {
      padding: get-vw(12px) get-vw(24px);
    }
    &:hover {
      color: var(--blue);
    }
  }

  &-top .wr,
  &-top_menu {
    gap: 24px;
    grid-gap: 24px;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
    }
  }

  &-top_left {
    gap: 20px 75px;
    grid-gap: 20px 75px;
    flex: 1;
    @include desktopStyle {
      gap: get-vw(20px) get-vw(75px);
      grid-gap: get-vw(20px) get-vw(75px);
    }

    :global(.logo) {
      img {
        width: auto;
        height: auto;
      }
    }
  }

  &-top_right {
    max-width: 42%;
    gap: 20px 48px;
    grid-gap: 20px 48px;
    @include desktopStyle {
      gap: get-vw(20px) get-vw(48px);
      grid-gap: get-vw(20px) get-vw(48px);
    }
  }

  &-top_menu-link {
    color: var(--gray);
    font-size: 1.4rem;
    font-family: $font-main-MD;
    text-decoration: none;
    &:hover {
      color: var(--blue);
    }
  }

  &-top_action {
    gap: 14px;
    grid-gap: 14px;
  }
}
