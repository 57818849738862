@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.cookie-notice {
  position: fixed;
  z-index: 990;
  bottom: 15px;
  right: 15px;
  border-radius: 10px;
  background: var(--white);
  padding: 20px;
  width: 100%;
  max-width: 50%;
  font-size: 1.6rem;
  box-shadow: 0 4px 32px 0 rgba(var(--black-rgba), 0.05);
  gap: 12px 20px;
  grid-gap: 12px 20px;
  transition: transform 0.3s;
  transform: translateX(150%);
  border: 1px solid var(--blue);
  &.b-active {
    transform: translateX(0);
  }
  @include desktopStyle {
    border-radius: get-vw(10px);
    padding: get-vw(20px);
    box-shadow: 0 get-vw(4px) get-vw(32px) 0 rgba(var(--black-rgba), 0.05);
    gap: get-vw(12px) get-vw(20px);
    grid-gap: get-vw(12px) get-vw(20px);
  }
  @media only screen and (max-width: $bp-tablet) {
    right: 0;
    bottom: 0;
    max-width: none;
    border-radius: 0;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.2rem;
    flex-direction: column;
    align-items: flex-start;
    :global(.btn) {
      font-size: 1.4rem;
      padding: 7px 22px;
    }
  }

  &_text {
    flex-grow: 1;
  }
}