@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.multi-level-menu {
  overflow: hidden;
  position: relative;

  &_list {
    background: var(--white);
    z-index: 5;
    width: 100%;

    &.b-bottom-menu {
      border-top: 1px solid #F0F0F0;
      padding-top: 16px;
      margin-top: 16px;
    }
  }

  &_back {
    border-bottom: 1px solid #F0F0F0;
    margin-bottom: 24px;

    .multi-level-menu_link {
      gap: 10px;
      grid-gap: 10px;
      padding: 10px 0;
      color: var(--gray-light);
      cursor: pointer;
    }
  }

  &_item {
    position: relative;
    &.b-with-children {
      border-bottom: 1px solid #F0F0F0;
      .multi-level-menu_link {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }

  &_link {
    text-decoration: none;
    color: var(--gray-dark);
    padding: 7px 24px 7px 0;
    gap: 12px;
    grid-gap: 12px;
    font-size: 1.5rem;
    line-height: 1.3;
  }

  &_arrow {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    color: var(--gray-light);
  }

  &_icon {
    width: 16px;
    height: 16px;
    img {
      display: block;
      max-height: 100%;
    }
  }

  &_label {
    background: rgba(var(--green-rgba), 0.2);
    font-family: $font-main-SB;
    font-size: 1.1rem;
    line-height: 1.3;
    padding: 3px 8px;
    border-radius: 66px;
    color: var(--green);
  }
}
