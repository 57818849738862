@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.button-scroll-top {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: var(--white);
  padding: 0;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 16px 0px rgba(53, 57, 61, 0.15);
  width: 55px;
  height: 55px;
  color: var(--gray-light);
  transition: all 0.3s;
  border-radius: 100%;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  &.b-show {
    opacity: 1;
    visibility: visible;
  }
  &:hover {
    background: var(--blue);
    color: var(--white);
  }
  // @media only screen and (max-width: $bp-tablet) {
  //   width: 46px;
  //   height: 46px;
  //   right: 16px;
  //   bottom: 16px;
  // }
  svg {
    width: 24px;
    height: 24px;
    display: block;
    // @include desktopStyle {
    //   width: get-vw(32px);
    //   height: get-vw(32px);
    // }
    // @media only screen and (max-width: $bp-tablet) {
    //   width: 16px;
    //   height: 16px;
    // }
  }
}
