@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.header-app-banner {
  position: relative;
  text-align: center;
  background: var(--white);
  padding: 50px;
  width: 442px;
  border-radius: 30px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
  @include desktopStyle {
    padding: get-vw(50px);
    width: get-vw(442px);
    border-radius: get-vw(30px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 32px 24px;
  }

  &__close {
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    &::after,
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background: var(--gray);
      left: 2px;
      border-radius: 4px;
    }
    &:after {
      transform: rotate(-45deg);
      top: 11px;
    }
    &::before {
      transform: rotate(45deg);
      bottom: 11px;
    }
  }
  &__label {
    display: inline-block;
    font-family: $font-main-MD;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6;
    padding: 4px 16px;
    background: var(--bg-label-blue-2);
    border-radius: 60px;
    margin-bottom: 16px;
    @include desktopStyle {
      padding: get-vw(4px) get-vw(16px);
      border-radius: get-vw(60px);
      margin-bottom: get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.4rem;
    }
  }
  &__title {
    font-family: $font-main-SB;
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 32px;
    @include desktopStyle {
      margin-bottom: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2.4rem;
    }
  }
  &__image {
    display: block;
    width: 100%;
    max-width: 270px;
    height: auto;
    margin: 0 auto 16px;
    @include desktopStyle {
      width: get-vw(270px);
      margin-bottom: get-vw(16px);
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    @include desktopStyle {
      gap: get-vw(4px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 2px;
    }

    &-qr {
      img {
        display: block;
        width: 160px;
        height: auto;
        @include desktopStyle {
          width: get-vw(160px);
        }
        @media only screen and (max-width: $bp-tablet) {
          width: 122px;
        }
      }
    }
    &-btns {
      flex: 1;
      a:not(:last-child) {
        margin-bottom: 16px;
        @include desktopStyle {
          margin-bottom: get-vw(16px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &--popup {
    width: 100%;
    max-width: 343px;
  }
}
