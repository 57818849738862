@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.footer-block {
  margin-top: 92px;
  width: 100%;
  @include desktopStyle {
    margin-top: get-vw(92px);
  }
  &:first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 0;
    border-top: 1px solid rgba(var(--gray-second-rgba), 0.4);
  }

  &.b-open {
    .footer-block_btn {
      transform: rotate(180deg);
      &::before {
        opacity: 0;
      }
    }
    .footer-block_content {
      @media only screen and (max-width: $bp-tablet) {
        opacity: 1;
        max-height: 2000px;
        transition: padding 0.3s, opacity 0.3s, max-height 0.7s;
        padding-top: 8px;
        padding-bottom: 28px;
      }
    }
  }

  &_content {
    transition: padding 0.3s, opacity 0.3s, max-height 0.3s;
    @media only screen and (max-width: $bp-tablet) {
      max-height: 0px;
      overflow: hidden;
      opacity: 0;
    }
  }

  &_title {
    font-size: 2rem;
    margin-bottom: 24px;
    gap: 24px;
    grid-gap: 24px;
    @include desktopStyle {
      margin-bottom: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.5rem;
      margin-bottom: 0;
      padding: 16px 0;
    }
  }

  &_btn {
    display: none;
    padding: 0;
    border: none;
    position: relative;
    background: transparent;
    outline: none;
    width: 36px;
    height: 36px;
    transition: transform 0.3s;
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }
    &::before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      background: var(--gray-light);
      left: 50%;
      top: 50%;
      transition: opacity 0.35s;
    }
    &::before {
      width: 2px;
      height: 16px;
      margin-left: -1px;
      margin-top: -8px;
    }
    &::after {
      width: 16px;
      height: 2px;
      margin-left: -8px;
      margin-top: -1px;
    }
  }
}
