// Font
$font-main: "Mulish", Arial, Helvetica, sans-serif;
$font-main-MD: "Mulish-Medium", Arial, Helvetica, sans-serif;
$font-main-MDI: "Mulish-MediumItalic", Arial, Helvetica, sans-serif;
$font-main-SB: "Mulish-SemiBold", Arial, Helvetica, sans-serif;
$font-main-B: "Mulish-Bold", Arial, Helvetica, sans-serif;
$font-main-BI: "Mulish-BoldItalic", Arial, Helvetica, sans-serif;

$bp-desktop-lg: 1920px;
$bp-desktop-md: 1600px;
$bp-desktop-sm: 1360px;
$bp-tablet: 1024px;
$bp-phone: 768px;
$bp-phone-sm: 480px;