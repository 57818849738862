@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.footer-contact {
  font-size: 1.4rem;
  margin-top: 48px;
  width: 100%;
  @include desktopStyle {
    margin-top: get-vw(48px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 0;
  }

  &_block {
    margin-bottom: 48px;
    @include desktopStyle {
      margin-bottom: get-vw(48px);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_title {
    font-size: 1.6rem;
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
    }
  }

  &_text {
    color: var(--gray);
    margin-top: 16px;
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    :global(a) {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline ;
      }
    }
  }

  &_phone {
    gap: 8px;
    grid-gap: 8px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
    color: var(--gray-dark);
    margin-top: 16px;
    text-decoration: none;
    @include desktopStyle {
      margin-top: get-vw(16px);
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
    }
    &:hover {
      color: var(--blue);
    }
  }

  &_phone-icon {
    color: var(--blue);
    display: block;
    min-width: 16px;
    @include desktopStyle {
      min-width: get-vw(16px);
      width: get-vw(16px);
      height: get-vw(16px);
    }
  }

  &_footnote {
    font-family: $font-main;
    margin-top: 8px;
    font-size: 1.2rem;
    line-height: 20px;
    margin-top: 8px;
    color: rgba(var(--gray-rgba), 0.4);
    max-width: 85%;
    @media only screen and (max-width: $bp-tablet) {
      color: rgba(var(--gray-dark-rgba), 0.6);
    }
  }

  &_link {
    font-family: $font-main;
    font-size: 1.4rem;
    line-height: 1.8;
    text-decoration-line: underline;
    color: var(--gray-dark);
    gap: 8px;
    grid-gap: 8px;
    margin-top: 16px;
    @include desktopStyle {
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
      margin-top: get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.5rem;
      color: var(--blue);
      margin-top: 24px;
      gap: 16px;
      grid-gap: 16px;
      line-height: 1.3;
      font-family: $font-main-SB;
      text-decoration: none;
    }
    &:hover {
      color: var(--blue);
      svg {
        transform: translateX(-2px);
      }
    }
    svg {
      display: block;
      transition: transform 0.3s;
      width: 12px;
      height: 12px;
      @include desktopStyle {
        width: get-vw(12px);
        height: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        width: 16px;
        height: 16px;
      }
    }
  }
}
